<template>
  <div class="simple-item">
    <div class="simple-item__image">
      <img
        :src="item ? item.image_url : ''"
        class="simple-item__img embed-responsive"
      />
    </div>

    <div
      class="simple-item__bg"
      :class="`background--${item ? item.rarity : ''}`"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'SimpleItem',
  props: { item: Object },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/style.scss';

.simple-item {
  width: 160px;
  height: 160px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  background: #1c2028;
  border-radius: 8px;

  &__bg {
    width: 120%;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &__img {
    -webkit-user-drag: none;
  }
  &__image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@each $rarity, $radio in $radios {
  .background--#{$rarity} {
    background: $radio;
  }
}

@each $rarity, $color in $rarities {
  .background--#{$rarity} {
    border-bottom: 3px solid $color;
  }
}
</style>
