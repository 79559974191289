<template>
  <div class="buy-sell">
    <div class="container">
      <div class="buy-sell__item card">
        <Item :item="item" />
        <div class="card-col">
          <div class="name text-white">{{ nameObject.excludeWear }}</div>
          <div class="text-secondary mt-3">
            <small>品质：{{ nameObject.wear }}</small>
            <small class="ml-4">类型：{{ type }}</small>
          </div>
          <div class="divider"></div>
          <div style="font-size:14px;line-height:30px">
            <span class="text-white">自由市场起售价格:</span>
            <span class="text-primary">￥{{ price }}</span>
          </div>
          <div style="font-size:14px;line-height:30px">
            <span class="text-white">官方商城在售价格:</span>
            <span class="text-primary">￥{{ price }}</span>
          </div>
        </div>
        <div class="action">
          <button class="btn btn-outline-danger text-white py-3 px-4">
            我要出售
          </button>
        </div>
      </div>

      <div class="buy-sell__offer card mt-4">
        <div class="text-white">当前求购</div>
        <div class="text-white mt-2">
          当前有
          <span class="text-primary">{{ numberOfPeople }}</span>
          人请求以
          <span class="text-primary">￥{{ price }}</span>
          或更低价格求购
        </div>
        <div class="action">
          <button class="btn btn-outline-info text-white py-3 px-4">
            我要求购
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-between flex-wrap my-4">
        <small v-if="sellingItems && !loading" class="found-label">
          当前在售共 {{ sellingItems.length }} 件饰品
        </small>
        <b-pagination
          class="m-0"
          v-model="currentPage"
          :total-rows="sellingItems.length"
          :per-page="perPage"
          first-number
          last-number
        ></b-pagination>
      </div>

      <Table :cols="cols" :data="pagedItems" :loading="loading">
        <template v-slot:name="{ item }">
          <div class="d-flex">
            <div class="table__col__img">
              <div
                class="img"
                :style="`background-image:url(${item.image_url})`"
              ></div>
            </div>
            <div class="table__col__name">
              <div class="name clamp">
                {{ nameFilter(item.name).excludeWear }}
              </div>
              <div class="type clamp">{{ item.type }}</div>
              <div class="wear clamp">{{ nameFilter(item.name).wear }}</div>
            </div>
          </div>
        </template>

        <template v-slot:seller="{ item }">
          <span class="text-white">{{ item.type }} </span>
        </template>

        <template v-slot:price="{ item }">
          <span class="text-primary"> ¥ {{ item.price }} </span>
        </template>

        <template v-slot:operation="{ item }">
          <button class="btn btn-outline-info px-4" @click="buy(item)">
            购买
          </button>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Item from './Item.vue'
import { nameFilter } from '@/utils'
import Table from '../Table.vue'
import { AppService } from '@/services/api'

const COLS = [
  { label: '名称', value: 'name' },
  { label: '卖家', value: 'seller', fixed: '200' },
  { label: '价格', value: 'price', fixed: '150' },
  { label: '操作', value: 'operation', fixed: '150' },
]

export default {
  name: 'BuySell',
  components: { Item, Table },
  data() {
    return {
      loading: false,
      item: null,
      sellingItems: [],
      perPage: 10,
      currentPage: 1,
      cols: COLS,
    }
  },
  async mounted() {
    this.hydratePage()
    await this.getSellingItems()
  },
  computed: {
    nameObject() {
      const name = this.item ? this.item.name : ''
      return nameFilter(name)
    },
    type() {
      if (this.item) return this.item.type
      return ''
    },
    price() {
      if (this.item) return this.item.price
      return ''
    },
    numberOfPeople() {
      if (this.item) return this.item.app_id
      return ''
    },
    pagedItems() {
      const index = (this.currentPage - 1) * this.perPage
      const max = this.sellingItems.length
      return this.sellingItems.slice(index, Math.min(max, index + this.perPage))
    },
  },
  methods: {
    buy(item) {
      console.log(item)
    },
    nameFilter(val) {
      return nameFilter(val)
    },
    hydratePage() {
      //TODO: Mock
      this.item = {
        name: '专业手套（★） | 翠绿之网 (久经沙场)',
        market_hash_name: '★ Specialist Gloves | Emerald Web (Field-Tested)',
        app_id: 730,
        price: '322.40',
        image_url:
          'https://steam.yskins.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1h3LAVbv6mxFABs3OXNYgJR_Nm1nYGHnuTgDL7ck3lQ5MFOnezDyoHwjF2hpiwwMiukcZjGJg85NQnR81LolObogsLo6MvJzHBlsyl04nvUmke-hxEfPeVojPXKVxzAUDsQyVOl',
        name_color: '8650AC',
        type: '★ 非凡 手套',
        rarity_color: 'red',
        base_name: '专业手套（★） | 翠绿之网',
      }
    },
    async getSellingItems() {
      this.loading = true
      const { data } = await AppService.getItems()
      this.loading = false
      this.sellingItems = data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/style.scss';

.buy-sell {
  width: 100%;
}
.buy-sell__item {
  height: 208px;
  display: flex;
  flex-direction: row;
}

.card-col {
  padding-left: 20px;
  width: 100%;
  flex: 1;
}

.divider {
  width: 100%;
  margin: 20px 0;
  border-bottom: 2px dashed #2d3139;
}

.action {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.found-label {
  color: #606c85;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
}

.buy-sell__offer {
}

.table__col__img {
  width: 150px;
  height: 120px;
  padding: 5px 10px;
  background-color: #1c2028;

  .img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.table__col__name {
  padding: 20px;
  flex: 1;

  .name {
  }
  .type {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
    color: #7b8ba7;
    -webkit-line-clamp: 1;
  }
  .wear {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
    -webkit-line-clamp: 1;
  }
}
</style>
